body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  padding-left: 60px;
  background: #FFFFFF;
  box-shadow: -11px -1px 11px rgba(0, 0, 0, 0.25);
}

.nav-menu__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu__container {
  font-family: 'Public Sans';
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 29px;
  font-weight: 500;
  line-height: 21.15px;
  letter-spacing: 0.05em;
  color: #6E687B;
}

.logo {
  width: 71px;
  height: 71px;
  margin-right: 17px;
}

.brand-name {
  display: flex;
  align-items: center;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.05em;

  color: #243875;
}

.navbar-brand {
  align-items: center;
  display: flex;
}

.top-section_container {
  padding-top: 87px;
  padding-bottom: 141px;
  width: 720px;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 66px;
  letter-spacing: 0.05em;
  color: #3E4450;
  margin-left: 67px;
}

.top-section_btn {
  display: block;
  margin-top: 43px;
}

.locker-btn {
  width: 192px;
  height: 51px;

  background: #243875;
  box-shadow: 0px 6px 15px -1px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  border: none;
  cursor: pointer;
}

.liquidty-section_container {
  padding-top: 151px;
}

.section_title {
  width: 394px;
  height: 47px;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #5F687B;
  margin: 0px auto;
}

.section_text {
  width: 840px;
  height: 21px;
  margin: 0px auto;
  text-align: center;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #444851;
}

.connect-btn{
  text-align: center;
  margin-top: 47px;
}

.mt-22{
  margin-top: 22px;
}

.mt-24{
  margin-top: 24px;
}